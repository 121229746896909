.gradient.color-scheme-2:has(.popup) {
  padding-bottom: 120px;
}
.card__inner {
  position: relative;
}
.overlay-content {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  transition: 0.15s linear all;
  display: flex;
  align-items: center;
  opacity: 0;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  z-index: 2;
}
a.my-quickview-button {
  background-color: #000;
  padding: 4px 30px;
  border-radius: 30px;
  margin-top: 20px;
  font-size: 16px;
  color: #fff;
}
.grid__item:hover .overlay-content {
  opacity: 1;
}
.price-per-item__container {
  margin-bottom: 25px;
}

/** popup css **/
.fancybox-slide.fancybox-slide--html.fancybox-slide--current {
  background-color: rgba(0, 0, 0, 0.8);
}
.fancybox-content {
  background-color: #000;
}

.product-details {
  width: 400px;
}
.popup-content {
  background-color: #000;
  padding: 20px;
  max-width: 75%;
  border-radius: 5px;
  box-shadow: 0px 0px 10px #fff;
}

.hidden {
  display: none;
}
.slick-arrow {
  top: calc(-650px - -450px) !important;
}
.close-popup {
  position: absolute;
  cursor: pointer;
  z-index: 1000;
  font-size: 24px;
}
.product-images {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  width: 400px;
}

.product-images > img {
  border-radius: 12px;
}

.thumbnail-images img {
  width: 100%;
  height: 100%;
  margin: 10px;
  cursor: pointer;
}

.product-title {
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 20px;
  border-bottom: 1px solid #fff;
}

.product-price {
  /* color: #a61e22; */
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}

.product-description {
  margin-bottom: 20px;
}

.view-details-link {
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
  display: block;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding: 20px 0;
  text-decoration: none;
  font-weight: bold;
}

.product-quantity {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.add-to-cart-popup {
  background-color: #ffffff;
  color: #212b36;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-family: "Quattrocento Sans";
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  width: 100%;
}
button.cart-disabled {
  background-color: #000;
  color: #d94b4b;
  padding: 10px 20px;
  cursor: not-allowed;
  border: 1px solid #fff;
  pointer-events: none;
}

.shop-pay-button {
  background-color: #4b24ff;
  color: #fff;
  padding: 10px 20px;
  margin-bottom: 10px;
}

.more-payment-options {
  color: #333;
}
.product-popup-content {
  position: relative;
  display: flex;
}
.popup-content button.quantity__button {
  background: #bfbfbf;
}

.popup-content button.quantity__button[name="plus"] {
  border-bottom-right-radius: 23px;
  border-top-right-radius: 23px;
}

.popup-content button.quantity__button[name="minus"] {
  border-bottom-left-radius: 23px;
  border-top-left-radius: 23px;
}

.popup-content quantity-input.quantity {
  border-radius: 23px;
  width: 180px;
}

.popup .button--full-width {
  margin-top: 20px;
  background: #000;
}
/* .slick-prev {
  left: -35px;
}

.slick-next {
  right: -35px;
}

.slick-dots {
  bottom: -30px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: #333;
}

.slick-dots li.slick-active button:before {
  color: #000;
} */

/* Main Image Slider */
.slider-for img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Thumbnail Slider */
.slider-nav {
  margin-top: 15px;
}

.slider-nav .slick-slide {
  opacity: 0.6;
  transition: opacity 0.3s ease;
}

.slider-nav .slick-current {
  opacity: 1;
}

.slider-nav img {
  width: 100%;
  height: auto;
  border: 2px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.slider-nav img:hover,
.slider-nav .slick-current img {
  border-color: #333;
}
.product-gallery {
  max-width: 95%;
  margin: 0 auto;
}
.product-gallery .slider-nav img {
  width: 87px;
  height: 87px;
  object-fit: cover;
  border-radius: 0px;
  border: none;
}
.product-gallery .slider-for img {
  width: 90%;
  margin: 0 auto;
}

.slider-for img,
.slider-nav img {
  width: 100%;
  height: auto;
}

.slider-nav .slick-slide {
  cursor: pointer;
}
.pop-up-row {
  display: flex;
  margin: 0 -15px;
}

.pop-up-col {
  flex: 1 1 50%;
  padding: 0 15px;
  box-sizing: border-box;
}

.pop-up-col img {
  width: 100%;
}
.title_prod {
  display: none;
}

span#qty-inc,
span#qty-dec {
  display: flex;
  background-color: #000;
  color: #fff;
  width: 15px !important;
  height: 25px;
  cursor: pointer;
  align-items: center;
}

.qty-flex {
  display: flex;
  gap: 15px;
  margin: 14px 0 20px;
  align-items: center;
  width: 35%;
  border: 2px solid #fff;
  text-align: center;
  justify-content: center;
  border-radius: 10px;
  padding: 5px 0;
}

.qty-flex input.quantity-input-popup {
  background: #000;
  border: none;
  outline: none;
  color: #fff;
  font-size: 15px;
  width: 50px;
  text-align: center;
}
.pop-up-col .product-notifier form#notify-form {
  display: flex;
}

/* loader css */
.video-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  z-index: 1000;
  background-color: red;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.slick-slide {
  position: relative;
}
@media (max-width: 1024px) {
  .pop-up-row {
    flex-wrap: wrap;
  }
  title_prod {
    display: block;
  }
  .pop-up-col {
    flex: 1 1 100%; /* Stack columns vertically on smaller screens */
    margin-bottom: 15px; /* Space between stacked columns */
  }
  a.my-quickview-button {
    display: none;
  }
}

@media (max-width: 540px) {
  .title_prod {
    display: block !important;
  }
  .pop-up-col {
    flex: 1 1 100%; /* Make columns 100% width on very small screens */
  }
}

.pop-up-col:last-child {
  margin-bottom: 0; /* Remove margin from the last column */
}

/* Responsive Styling */
@media (max-width: 768px) {
  .slick-prev,
  .slick-next {
    font-size: 14px;
  }
  .slick-prev {
    left: -25px;
  }
  .slick-next {
    right: -25px;
  }
}

@media (max-width: 480px) {
  .slick-prev,
  .slick-next {
    font-size: 12px;
  }
  .slick-prev {
    left: -15px;
  }
  .slick-next {
    right: -15px;
  }
}
